import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS,Flex,Link } from '@potluckprotocol/uikit'
import BigNumber from 'bignumber.js'
import useToast from 'hooks/useToast'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPublicPoolsData } from 'state/pools/hooks'
import useGetDocumentTitlePrice from 'hooks/useGetDocumentTitlePrice'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData, usePollFarmsPublicData } from 'state/farms/hooks'
import { useSaveReferrer } from 'state/referrals/hooks'
import { useFetchPriceList } from 'state/prices/hooks'
import { useGasPrice } from 'state/user/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
// Views included in the main bundle
// import Pools from './views/BatBox'
// import Observatory from './views/Observatory'
import Swap from './views/Swap'

import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
// const Referrals = lazy(() => import('./views/Referrals'))
const Rubic = lazy(() => import('./views/RubicCrossChainSwap'))
const Farms = lazy(() => import('./views/Farms'))
const Attic = lazy(() => import('./views/Attic'))
// const BatBattle = lazy(() => import('./views/BatBattle'))
const NotFound = lazy(() => import('./views/NotFound'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})


let didAskToJoinDiscord = false;

const App: React.FC = () => {
  useFetchPriceList()
  useGetDocumentTitlePrice()
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  useSaveReferrer()
  useGasPrice()

  const { toastSuccess} = useToast()
  if (Math.random() < 0.35 && !didAskToJoinDiscord){  
    toastSuccess("Have you joined our Discord community?", 
    <Flex flexDirection="column">
    <Link external href="https://discord.gg/potluckprotocol">
        Join NOW
    </Link>
  </Flex>,);
  }
  didAskToJoinDiscord = true;

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/cave">
              <Farms />
            </Route>
            <Route path="/attic">
              <Attic />
            </Route>
            {/* <Route path="/batbox">
              <Pools />
            </Route>
            <Route path="/observatory">
              <Observatory />
            </Route>
            <Route path="/batbattle">
              <BatBattle />
            </Route>
            <Route path="/referrals">
              <Referrals />
            </Route> */}
            <Route path="/rubic">
              <Rubic/>
              </Route>

            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
            <Route exact strict path="/find" component={PoolFinder} />
            <Route exact strict path="/liquidity" component={Liquidity} />
            <Route exact strict path="/create" component={RedirectToAddLiquidity} />
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact path="/create" component={AddLiquidity} />
            <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

            {/* Redirect */}
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
