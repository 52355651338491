import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Potluck Protocol',
  description: 'Everyone Eats. Gatherin around on the Fantom Chain.',
  image: 'https://potluckprotocol.com/images/egg/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: t('Home | FANG'),
      }
    case '/attic':
      return {
        title: 'The Attic | FANG',
      }
    case '/cave':
      return {
        title: 'The Cave | FANG',
      }
    case '/batbox':
      return {
        title: 'The Bat Box | FANG',
      }
    case '/batbattle':
      return {
        title: 'The Bat Battle | FANG',
      }
    case '/observatory':
      return {
        title: 'The Observatory | FANG',
      }
    case '/referrals':
      return {
        title: 'Referrals | FANG',
      }
    default:
      return null
  }
}
