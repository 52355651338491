import { ChainId, Token } from '@potluckprotocol/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x49894fcc07233957c35462cfc3418ef0cc26129f',
    18,
    'FANG',
    'FANG Token',
  ),
  [ChainId.FTMTESTNET]: new Token(
    ChainId.FTMTESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}

export const FANG: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x49894fcc07233957c35462cfc3418ef0cc26129f',
    18,
    'FANG',
    'FANG Token',
  ),
  [ChainId.FTMTESTNET]: new Token(
    ChainId.FTMTESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  )
}

export const FUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
    18,
    'FUSD',
    'FTM USD',
  ),
  [ChainId.FTMTESTNET]: new Token(
    ChainId.FTMTESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}

export const USDT: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x049d68029688eabf473097a2fc38ef61633a3c7a', 6, 'USDT', 'Tether USD'),
  [ChainId.FTMTESTNET]: new Token(
    ChainId.FTMTESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    6,
    'BUSD',
    'Binance USD',
  ),
}

export const WFTM = new Token(ChainId.MAINNET, '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18, 'WFTM', 'Wrapped FTM')
export const DAI = new Token(ChainId.MAINNET, '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E', 18, 'DAI', 'Dai Stablecoin')
export const WBTC = new Token(ChainId.MAINNET, '0x321162cd933e2be498cd2267a90534a804051b11', 8, 'WBTC', 'Wrapped BTC')
export const ETH = new Token(
  ChainId.MAINNET,
  '0x74b23882a30290451A17c44f4F05243b6b58C76d',
  18,
  'ETH',
  'Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  6,
  'USDC',
  'USD Coin',
)

const tokens = {
  ftm: {
    symbol: 'FTM',
    projectLink: 'https://fantom.foundation/',
  },
  lqdr:{
    symbol: 'LQDR',
    address: {
      250: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://www.liquiddriver.finance/',
  },
  slurp:{
    symbol: 'SLURP',
    address: {
      250: '0x26aeb3441428eeaf7d653e9a5b455c57dd8d8eeb',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://slurp.wtf/',
    
  },
  tomb:{
    symbol: 'TOMB',
    address: {
      250: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://tomb.finance/',
    
  },
  wbtc: {
    symbol: 'WBTC',
    address: {
      250: '0x321162cd933e2be498cd2267a90534a804051b11',
      97:  '',
    },
    decimals: 8,
    projectLink: 'https://bitcoin.org/',
  },
  link:{
    symbol: 'LINK',
    address: {
      250: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://chain.link/',
  },
  mim:{
    symbol: 'MIM',
    address: {
      250: '0x82f0b8b456c1a451378467398982d4834b6829c1',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://abracadabra.money',
  },
  ice:{
    symbol: 'ICE',
    address: {
      250: '0xf16e81dce15b08f326220742020379b855b87df9',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://popsicle.finance',
  },
  spell:{
    symbol: 'SPELL',
    address: {
      250: '0x468003b688943977e6130f4f68f23aad939a1040',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://abracadabra.money',
  },
  tarot:{
    symbol: 'TAROT',
    address: {
      250: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://www.tarot.to/',
  },
  spirit:{
    symbol: 'SPIRIT',
    address: {
      250: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://www.spiritswap.finance/',
  },
  boo:{
    symbol: 'BOO',
    address: {
      250: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://spookyswap.finance/',
  },
  usdc:{
    symbol: 'USDC',
    address: {
      250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      97:  '',
    },
    decimals: 6,
    projectLink: 'https://fantom.foundation/defi',
  },
  usdt:{
    symbol: 'FUSDT',
    address: {
      250: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
      97:  '',
    },
    decimals: 6,
    projectLink: 'https://fantom.foundation/defi',
  },
  fusd:{
    symbol: 'FUSD',
    address: {
      250: '0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://fantom.foundation/defi',
  },
  wftm:{
    symbol: 'WFTM',
    address: {
      250: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://fantom.foundation/defi',
  },
  ptlkx: {
    symbol: 'PTLKX',
    address: {
      250: '0x546d10c1378febef063b72ae4865496ad801c386',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://potluckprotocol.com/',
  },
  fang:{ 
    symbol: 'FANG',
    address: {
      250: '0x49894fcc07233957c35462cfc3418ef0cc26129f',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://fantom.potluckprotocol.com/',
  },
  test: {
    symbol: 'TEST TOKEN',
    address: {
      250: '0xCf73095BDAc6Af4c6935cD5c19BfD6662388B249',
      97:  '',
    },
    decimals: 18,
    projectLink: 'https://fantom.potluckprotocol.com/',
  },
  
}

export default tokens
