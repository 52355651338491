import tokens from './tokens'
import pairs from './pairs'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    isBatBox: true,
    sousId: 3,
    stakingToken: tokens.ptlkx,
    earningToken: tokens.fang,
    contractAddress: {
      97: '',
      250: '0x6ee8f195913cc219706ceda0aaab3733223d421d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0025',
    sortOrder: 1,
    isFinished: false,
  },
  {
    isBatBox: true,
    sousId: 4,
    stakingToken: tokens.ptlkx,
    earningToken: tokens.fang,
    contractAddress: {
      97: '',
      250: '0x41589c579F94f482d845eD6e9b69E66d38470689',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00125',
    sortOrder: 1,
    isFinished: false,
  },
    {
    sousId: 5,
    isBatBox: true,
    stakingToken: pairs.fang_ftm_spirit,
    earningToken: tokens.fang,
    contractAddress: {
      97: '',
      250: '0x9ff25a687f131052052c170df5d99de57450b422',
    },
    poolCategory: PoolCategory.SPIRIT,
    harvest: true,
    tokenPerBlock: '0.002',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 6,
    isBatBox: true,
    stakingToken: pairs.fang_ptlkx,
    earningToken: tokens.fang,
    contractAddress: {
      97: '',
      250: '0x80f32ccd74219ec6d059f6c2a12ade84f8aff55a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00077',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 7,
    isObservatory:true,
    stakingToken: tokens.fang,
    earningToken: tokens.slurp,
    contractAddress: {
      97: '',
      250: '0xd0587d005d0e4adcc2bd31835a8f92cce4394478',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00001157407',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 8,
    isObservatory:true,
    stakingToken: tokens.fang,
    earningToken: tokens.slurp,
    contractAddress: {
      97: '',
      250: '0x218eB9Eef6B6Ec16D812Bf13B3814253c11eb355',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00000658656',
    sortOrder: 1,
    isFinished: false,
  },
  
]

export default pools
