import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 26,
    lpSymbol: 'FANG-FTM LP',
    lpAddresses: {
      97: '',
      250: '0x871dd566ab3de61e5cc8fb16fee82595b17e9cc6',
    },
    token: tokens.fang,
    quoteToken: tokens.wftm,
    isCommunity:true
  },
  {
    pid: 27,
    lpSymbol: 'FANG-USDC LP',
    lpAddresses: {
      97: '',
      250: '0x81018710Ff976d7E17Df41Ab8825bD626Ff4B73a',
    },
    token: tokens.fang,
    quoteToken: tokens.usdc,
    isCommunity: true,
  },
  {
    pid: 28,
    lpSymbol: 'FANG-TOMB LP',
    lpAddresses: {
      97: '',
      250: '0x670d86356ea4112bd92919c6413b288313b41484',
    },
    token: tokens.fang,
    quoteToken: tokens.tomb,
 
  },
  {
    pid: 0,
    lpSymbol: 'FANG-FTM LP',
    lpAddresses: {
      97: '',
      250: '0x19dce7bde30cd966ff123d272e5f688e0b4b8b78',
    },
    token: tokens.fang,
    quoteToken: tokens.wftm,
  },
  {
    pid: 21,
    lpSymbol: 'FANG-FTM LP',
    lpAddresses: {
      97: '',
      250: '0x871dd566ab3de61e5cc8fb16fee82595b17e9cc6',
    },
    isCommunity: true,
    token: tokens.fang,
    quoteToken: tokens.wftm,
  },
  {
    pid: 1,
    lpSymbol: 'FANG-FUSDT LP',
    lpAddresses: {
      97: '',
      250: '0x0aac53361f345fc0698f441f96b3584bf0eda09a',
    },
    token: tokens.fang,
    quoteToken: tokens.usdt,
  },
  {
    pid: 2,
    lpSymbol: 'USDC-FUSDT LP',
    lpAddresses: {
      97: '',
      250: '0xfdef392adc84607135c24ca45de5452d77aa10de',
    },
    token: tokens.usdc,
    quoteToken: tokens.usdt,
  },
  {
    pid: 3,
    lpSymbol: 'FTM-FUSDT LP',
    lpAddresses: {
      97: '',
      250: '0x5965E53aa80a0bcF1CD6dbDd72e6A9b2AA047410',
    },
    token: tokens.usdt,
    quoteToken: tokens.wftm,
  },
  {
    pid: 4,
    lpSymbol: 'BOO-FTM LP',
    lpAddresses: {
      97: '',
      250: '0xec7178f4c41f346b2721907f5cf7628e388a7a58',
    },
    token: tokens.boo,
    quoteToken: tokens.wftm,
  },
  {
    pid: 5,
    lpSymbol: 'PTLKX-FTM LP',
    lpAddresses: {
      97: '',
      250: '0x2282f8def5194abf5a2d451efad4de83b7286559',
    },
    token: tokens.ptlkx,
    quoteToken: tokens.wftm,
  },
  {
    pid: 6,
    lpSymbol: 'FANG',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      250: '0x49894fcc07233957c35462cfc3418ef0cc26129f',
    },
    token: tokens.fang,
    quoteToken: tokens.fang,
  },
  {
    pid: 29,
    lpSymbol: 'LQDR',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      250: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    },
    token: tokens.lqdr,
    quoteToken: tokens.lqdr,
  },
  {
    pid: 7,
    lpSymbol: 'WFTM',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      250: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    },
    token: tokens.wftm,
    quoteToken: tokens.wftm,
  },
  {
    pid: 8,
    lpSymbol: 'BOO',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      250: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    },
    token: tokens.boo,
    quoteToken: tokens.boo,
  },
  {
    pid: 9,
    lpSymbol: 'SPIRIT',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      250: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
    },
    token: tokens.spirit,
    quoteToken: tokens.spirit,
  },
  {
    pid: 10,
    lpSymbol: 'TAROT',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      250: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    },
    token: tokens.tarot,
    quoteToken: tokens.tarot,
  },
  {
    pid: 11,
    lpSymbol: 'TAROT-FTM LP',
    lpAddresses: {
      97: '',
      250: '0x11d90ea9d16e1ee5879b299a819f6d618816d70f',
    },
    token: tokens.tarot,
    quoteToken: tokens.wftm,
  },
  {
    pid: 12,
    isTokenOnly: true,
    lpSymbol: 'SPELL',
    lpAddresses: {
      97: '',
      250: '0x468003b688943977e6130f4f68f23aad939a1040',
    },
    token: tokens.spell,
    quoteToken: tokens.spell,
  },
  {
    pid: 13,
    isTokenOnly: true,
    lpSymbol: 'ICE',
    lpAddresses: {
      97: '',
      250: '0xf16e81dce15B08F326220742020379B855B87DF9',
    },
    token: tokens.ice,
    quoteToken: tokens.ice,
  },
  {
    pid: 16,
    lpSymbol: 'FANG-MIM LP',
    lpAddresses: {
      97: '',
      250: '0x44c3cf28a0b0fc8a42f4cd309cd0ff32e0fe1c58',
    },
    token: tokens.mim,
    quoteToken: tokens.fang,
  },
  {
    pid: 17,
    isTokenOnly: true,
    lpSymbol: 'LINK',
    lpAddresses: {
      97: '',
      250: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    },
    token: tokens.link,
    quoteToken: tokens.link,
  },
  {
    pid: 18,
    isTokenOnly: true,
    lpSymbol: 'WBTC',
    lpAddresses: {
      97: '',
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    },
    token: tokens.wbtc,
    quoteToken: tokens.wbtc,
  },
  {
    pid: 19,
    lpSymbol: 'WBTC-FTM LP',
    lpAddresses: {
      97: '',
      250: '0xfdb9ab8b9513ad9e419cf19530fee49d412c3ee3',
    },
    token: tokens.wbtc,
    quoteToken: tokens.wftm,
  },
  {
    pid: 20,
    lpSymbol: 'USDC-FTM LP',
    lpAddresses: {
      97: '',
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    },
    token: tokens.usdc,
    quoteToken: tokens.wftm,
  },
  {
    pid: 22,
    isTokenOnly: true,
    lpSymbol: 'TOMB',
    lpAddresses: {
      97: '',
      250: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    },
    token: tokens.tomb,
    quoteToken: tokens.tomb,
  },
  {
    pid: 23,
    lpSymbol: 'TOMB-FTM LP',
    lpAddresses: {
      97: '',
      250: '0x2a651563c9d3af67ae0388a5c8f89b867038089e',
    },
    token: tokens.tomb,
    quoteToken: tokens.wftm,
  },
  {
    pid: 24,
    lpSymbol: 'SLURP-MIM LP',
    lpAddresses: {
      97: '',
      250: '0x402474d409fe3376d9d90effe8a478659beef14d',
    },
    token: tokens.slurp,
    quoteToken: tokens.mim,
  },
  {
    pid: 25,
    isTokenOnly: true,
    lpSymbol: 'SLURP',
    lpAddresses: {
      97: '',
      250: '0x26aeb3441428eeaf7d653e9a5b455c57dd8d8eeb',
    },
    token: tokens.slurp,
    quoteToken: tokens.slurp,
  }
  
]

export default farms
