const pairs = {
  fang_ftm_spirit: {
    symbol: 'FANG-WFTM-LP SPIRIT',
    address: {
      250: '0x871dd566ab3de61e5cc8fb16fee82595b17e9cc6',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://info.spiritswap.finance/pair/0x871dd566ab3de61e5cc8fb16fee82595b17e9cc6',
  },
  fang_ptlkx: {
    symbol: 'FANG-PTLKX-LP',
    address: {
      250: '0x7f75734240d03013e515c7a5357acd797142b72c',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://info.spookyswap.finance/pair/0x7f75734240d03013e515c7a5357acd797142b72c',
  }
}

export default pairs
